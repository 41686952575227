<template>
    <router-view />

    <portal-target
        name="overlays"
        multiple
    ></portal-target>

    <FlashMessages />

    <TheAuthOverlay v-if="activeAuthOverlay" />

    <GlobalEvents
        v-if="globalListenersEnabled"
        @click.capture="globalClickEvent"
    ></GlobalEvents>
</template>

<script lang="ts">
import { defineAsyncComponent, defineComponent, provide } from 'vue';
import FlashMessages from '@components/FlashMessages/FlashMessages.vue';
import { findLinkInEvent } from '@/helpers/event';
import { isFirefox } from '@/helpers/browser';
import { pushGtmEvent, GtmEventName } from '@/utils/gtmUtils';
import { GlobalEvents } from 'vue-global-events';
import { clickEvent } from '@/modules/globalEvents';
import { mapGetters } from 'vuex';
import { provideIsDebugModeKey } from '@/components/keys';
import { EventBus, EventBusEvent } from '@/plugins/eventBus';
import { trackEvent } from '@/modules/tracking';
import { useUserStore } from '@/stores/user';
import { mapActions } from 'pinia';

export default defineComponent({
    name: 'App',
    components: {
        FlashMessages,
        GlobalEvents,
        TheAuthOverlay: defineAsyncComponent(() => import('@/components/TheAuthOverlay')),
    },
    setup() {
        provide(provideIsDebugModeKey, import.meta.env.MIX_DEBUG === 'true');
    },
    computed: {
        ...mapGetters('ui', [
            'globalListenersEnabled',
            'activeAuthOverlay',
        ]),
        ...mapGetters('auth', ['isLoggedIn']),
    },
    created() {
        // for click Events
        window.addEventListener('click', this.onClickCapture, true);
        // for contextmenu Events
        window.addEventListener('contextmenu', this.onClickCapture, true);
        // track auth refresh token failed
        if (this.isLoggedIn) {
            if (!useUserStore().userId) {
                this.loadUserData(true);
            }
            EventBus.on(EventBusEvent.CLEAR_USER_DATA, () => useUserStore().logout());
        } else useUserStore().logout();
        EventBus.on(EventBusEvent.TRACK_TOKEN_REFRESH_FAILED, this.trackAuthTokenRefreshFailed);
    },
    beforeUnmount() {
        window.removeEventListener('click', this.onClickCapture, { capture: true });
        window.removeEventListener('contextmenu', this.onClickCapture, { capture: true });
        EventBus.off(EventBusEvent.TRACK_TOKEN_REFRESH_FAILED, this.trackAuthTokenRefreshFailed);
        EventBus.off(EventBusEvent.CLEAR_USER_DATA, () => useUserStore().logout());
    },
    methods: {
        ...mapActions(useUserStore, ['loadUserData']),
        onClickCapture(e: MouseEvent) {
            const link = findLinkInEvent(e);
            if (!link) return;

            this.onLinkClick(link);
        },
        onLinkClick(link: HTMLLinkElement) {
            const url = new URL(link.href, window.location.origin);
            if (url.hostname.includes('studydrive')) {
                // the user stays on studydrive pages, so don't bother
                return;
            }

            if (link.target === '_blank') {
                // make sure that all the external link click on our page dont have: opener referrer (for privacy reasons)
                // eslint-disable-next-line no-param-reassign
                link.rel = isFirefox ? 'noopener noreferrer' : 'noopener';
            }

            pushGtmEvent(GtmEventName.LINK_OUT);
        },
        globalClickEvent(e: MouseEvent) {
            clickEvent(e, this.$store, this.$i18next);
        },
        trackAuthTokenRefreshFailed() {
            trackEvent('auth', 'refresh_token_failed');
        },
    },
});
</script>

<style scoped lang="scss"></style>
