import type { Conversation } from '@/views/AiChats/types';
import type { DataResponse } from '@/api/types';
import type {
    ConversationMessagesResource,
    ConversationResource,
    StarterPromptsResource,
    MessageResource,
} from '@/api/gateway/alchemist/resources/AiChatsResource';
import type { Filter as MessageFilter } from '@/components/AiChats/AiChatInputField/types';
import { gateway } from '..';
import type { DocumentSummaryResource } from './resources/DocumentSummaryResource';

const apiPath = 'alchemist/api/v1';

export function getDocumentSummary(id: number) {
    return gateway.get<{ summary: DocumentSummaryResource[] }>(`${apiPath}/documents/${id}/summary`);
}

export function generateDocumentSummary(
    document_id: number,
    document_file_name: string,
    document_description: string,
    number_of_pages: number,
) {
    return gateway.post(`${apiPath}/documents/summary`, {
        document_id,
        document_file_name,
        document_description,
        number_of_pages,
    });
}

// AI Chat Routes
export function getConversations(page = 1, perPage = 20) {
    return gateway
        .get<ConversationResource>(`${apiPath}/conversations`, {
            params: { per_page: perPage, page },
        })
        .then((response) => response.data);
}

export function getConversationMessages(
    conversationId: number,
    oldestLoadedMessageId?: number,
    newestLoadedMessageId?: number,
) {
    return gateway
        .get<ConversationMessagesResource>(`${apiPath}/conversations/${conversationId}/messages`, {
            params: {
                oldest_loaded_message_id: oldestLoadedMessageId,
                newest_loaded_message_id: newestLoadedMessageId,
            },
        })
        .then((response) => response.data);
}

export function sendMessage(message: string, conversationId?: number, filters?: MessageFilter) {
    return gateway
        .post<MessageResource>(`${apiPath}/messages/message`, {
            message,
            conversation_id: conversationId,
            filters,
        })
        .then((response) => response.data);
}

export function updateConversation(conversationId: number, name: string) {
    return gateway
        .put<DataResponse<Conversation>>(`${apiPath}/conversations/${conversationId}`, {
            name,
        })
        .then((response) => response.data);
}

export function deleteConversation(conversationId: number) {
    return gateway.delete<VoidFunction>(`${apiPath}/conversations/${conversationId}`);
}

export function feedbackMessage(messageId: number, rate: boolean) {
    return gateway.put<VoidFunction>(`${apiPath}/messages/${messageId}/feedback`, { rate });
}

export function getStarterPrompts() {
    return gateway
        .get<StarterPromptsResource>(`${apiPath}/conversations/starter-prompts`)
        .then((response) => response.data);
}
