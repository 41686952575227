import { getCurrentInstance } from 'vue';

let idCounter = 0;

/**
 * Returns a unique id for the current component.
 * It will save the id as $id to the component instance and will reuse that id if called multiple times in a setup function.
 *
 * @important When not used in a setup function, it will return a new id each time.
 */
export function useId() {
    const vm = getCurrentInstance();

    // load id from component instance
    if (vm?.proxy?.$id) return vm.proxy.$id;

    idCounter += 1;
    const id = idCounter.toString();

    // set id on component instance
    if (vm?.proxy) {
        vm.proxy.$id = id;
    }

    return id;
}
