import VuexPersister from 'vuex-persister';
import { convertDateTimeStringsToDate } from '@helpers/date';

const persister = new VuexPersister({
    statesToPersist: [
        // list of store state attribute paths you want to persist here:
        'chats.totalUnreadMessages',
    ],
    getState(key, storage) {
        const data = JSON.parse(storage.getItem(key) ?? '{}');
        // we create date objects again from date strings
        return convertDateTimeStringsToDate(data);
    },
});

// As the persist plugin does not offer syncronization across tabs, we are enhancing the plugin
export default function persistPlugin(store) {
    // initialize the plugin
    persister.persist(store);

    // the `storage` event will be triggered by any tab that changes the loalStorage of the same domain
    // no need to remove this listener since we use it in a vuex store plugin and the store is loaded on every page
    // eslint-disable-next-line tipsi/remove-event-listener
    window.addEventListener('storage', (event) => {
        if (event.key !== 'vuex' || !event.newValue) return;

        // whenever the vuex value from the local storage changes, we apply the changes to our store.
        persister.rehydrateState(persister.overwrite, store, event.key, persister.storage);
    });
}
