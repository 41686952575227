import { StudyMaterialsSortType } from '@api/gateway/legacy-api/search';
import { i18n } from '@/plugins/i18n';

/**
 * Maps backend search aggregations to an object for better usability.
 * The backend returns aggrgations in the format of: [{key: 'a', doc_count: #}, {key: 'b'', doc_count: #}, ...]
 * We map them to: {a: #, b: #}
 * @param {Array} array
 * @returns {Object}
 */
export function mapAggregationsToObject(array = []) {
    return array.reduce((obj, curr) => {
        // @ts-ignore: unmatched type due to dynamic indexing
        // eslint-disable-next-line no-param-reassign
        obj[curr.key] = curr.doc_count;
        return obj;
    }, {});
}

export enum StudyMaterialTypeId {
    Flashcards = 200,
    Summaries = 80,
    Lectures = 20,
    Assignments = 30,
    Exams = 60,
    Other = 10,
}

export const ACADEMIC_YEAR_MIN = 2000;
export const ACADEMIC_YEAR_MAX = new Date().getFullYear();

export function sortLabel(sortKey: StudyMaterialsSortType): string {
    switch (sortKey) {
        case StudyMaterialsSortType.BestMatch:
            return i18n.t('common:filter.sort_by.documents.relevance');
        case StudyMaterialsSortType.Downloads:
            return i18n.t('common:filter.sort_by.documents.downloads');
        case StudyMaterialsSortType.Latest:
            return i18n.t('common:filter.sort_by.documents.latest');
        case StudyMaterialsSortType.Rating:
            return i18n.t('common:filter.sort_by.documents.most_liked');
        case StudyMaterialsSortType.VoteRating:
            return i18n.t('common:filter.sort_by.documents.vote_rating');
        default:
            return '';
    }
}
