const features = {
    careerTracking: import.meta.env.MIX_FEATURE_CAREER_TRACKING === 'true',
    guestUploads: import.meta.env.MIX_FEATURE_GUEST_UPLOADS === 'true',
    anonymityIdentity: import.meta.env.MIX_ANONYMITY_ENABLED === 'true',
    imageHandler: import.meta.env.MIX_IMAGE_HANDLER_ENABLED === 'true',
};

export type FeatureName = keyof typeof features;

export const getAllFeatures = () => features;

export const featureIsEnabled = (feature: FeatureName): boolean => features[feature] || false;
