<template>
    <div>
        <label
            v-if="label"
            :for="noAutofocus ? 'noinput' : undefined"
            class="inline-flex mb-2"
        >
            <span class="inline-block text-body-3 break-label-text">{{ label }}</span>
            <span
                v-if="labelTooltipText"
                class="ml-2"
            >
                <i
                    v-tooltip="{
                        content: labelTooltipText,
                        placement: 'auto',
                        showTriggers: ['hover', 'click'],
                    }"
                    class="w-5 h-5 icon icon-info"
                ></i>
            </span>
        </label>
        <slot :state="state" />
        <div
            v-if="!noFeedback"
            class="assistive-text text-body-3 text-left"
        >
            <div
                v-if="state === false"
                class="text-red-600"
            >
                <slot name="invalid-feedback">
                    <div
                        v-for="msg in getInvalidFeedback()"
                        :key="msg as string"
                    >
                        {{ msg }}
                    </div>
                </slot>
            </div>
            <div
                v-else-if="state === true"
                class="text-green-500"
            >
                <slot name="valid-feedback">{{ validFeedback }}</slot>
            </div>
            <div v-else-if="vuelidate && vuelidate.$model">
                <slot name="feedback">
                    <div
                        v-for="msg in assureArray(feedback)"
                        :key="msg"
                    >
                        {{ msg }}
                    </div>
                </slot>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, computed, type PropType } from 'vue';
import type { Validation } from '@vuelidate/core';
import { provideInputLabelKey } from '@/components/keys';
import { assureArray } from '@/helpers/array';
import { flatten } from 'lodash-es';
import type { State } from './InputContainer';

/**
 * This component is a wrapper for 1 input element and provides logic to apply labels & feedback messages,
 * as well as evaluating and providing state information about vuelidate validation
 */
export default defineComponent({
    name: 'InputContainer',
    provide() {
        return {
            [provideInputLabelKey as symbol]: computed(() => this.label),
        };
    },
    props: {
        /**
         * Vuelidate object for easy state & feedback evaluation
         */
        vuelidate: {
            type: Object as PropType<Validation | undefined>,
            default: undefined,
        },
        /**
         * The feedback message(s) to be displayed.
         */
        feedback: {
            type: [String, Array] as PropType<string | string[]>,
            default: null,
        },
        /**
         * The feedback message(s) to be displayed when the input is invalid.
         */
        invalidFeedback: {
            type: [Function, String, Array] as PropType<(() => string) | string | string[]>,
            default: null,
        },
        /**
         * The feedback message to be displayed when the input is valid.
         */
        validFeedback: {
            type: String,
            default: null,
        },
        /**
         * The input label.
         */
        label: {
            type: String,
            default: null,
        },
        /**
         * Disables feedback messages.
         */
        noFeedback: {
            type: Boolean,
            default: false,
        },
        /**
         * Disables autofocus on label click
         */
        noAutofocus: {
            type: Boolean,
            default: false,
        },
        /**
         * The label tooltip text.
         */
        labelTooltipText: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            state: null as boolean | null,
        };
    },
    watch: {
        vuelidate: {
            deep: true,
            handler(val) {
                this.state = val.$dirty && val.$error ? false : null;
            },
        },
    },
    methods: {
        assureArray,
        getInvalidFeedback() {
            if (!this.invalidFeedback && this.vuelidate) {
                return flatten(this.vuelidate.$errors.map((e) => e.$message));
            }

            if (typeof this.invalidFeedback === 'function') {
                return assureArray(this.invalidFeedback());
            }
            return assureArray(this.invalidFeedback);
        },
    },
});
</script>

<style>
.assistive-text {
    min-height: 20px;
}

.break-label-text {
    word-break: normal;
    overflow-wrap: break-word;
}
</style>
