import { ref, computed } from 'vue';
import { once } from 'lodash-es';
import { useAccessToken } from './accessToken';

export const useCommonHeaders = once(() => {
    const accept = ref(['application/json', 'text/plain', '*/*']);
    const { accessToken } = useAccessToken();

    return {
        Accept: computed(() => accept.value.join()),
        Authorization: computed(() => (accessToken.value ? `Bearer ${accessToken.value}` : undefined)),
    };
});
