export enum LocalStorageKeys {
    USER_EXPANDED_SIDEBAR = 'user_expanded_sidebar',
    REGISTRATION_ENTRY_POINT = 'registrationEntryPoint',
    SEARCH_UNIVERSITY_ATTEMPTS = 'search-university-attempts',
    PINIA = 'pinia',
}

export const removeFromLocalStorage = (key: string, subKey?: string) => {
    const storedData = localStorage.getItem(key);

    if (storedData) {
        const parsedData = JSON.parse(storedData);

        if (subKey) {
            if (subKey in parsedData) {
                delete parsedData[subKey];
                localStorage.setItem(key, JSON.stringify(parsedData));
            }
        } else {
            localStorage.removeItem(key);
        }
    }
};
