import { prefersReducedMotionQuery, prefersReducedMotion } from '@helpers/browser';

export default function preferReducedMotionSync(store) {
    const func = () => {
        store.commit('ui/SET_PREFERS_REDUCED_MOTION', prefersReducedMotion());
    };

    if (prefersReducedMotionQuery.addEventListener) {
        // no need to remove this listener since we use it in a vuex store plugin and the store is loaded on every page
        // eslint-disable-next-line tipsi/remove-event-listener
        prefersReducedMotionQuery.addEventListener('change', func);
    } else if (prefersReducedMotionQuery.addListener) {
        // safari 12 & 13 do not support addEventListener
        prefersReducedMotionQuery.addListener(func);
    }
}
