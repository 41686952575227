import type { MutationTree, GetterTree, ActionTree } from 'vuex';
import { findLast, last, orderBy } from 'lodash-es';
import { captureException } from '@sentry/vue';
import {
    storeChat,
    fetchChats,
    storeMessage,
    deleteMessage,
    fetchMessages,
    fetchNewMessages,
    fetchUnreadMessages,
    getPreferences,
    setPreferences,
} from '@/api/gateway/messages';
import type { ChatMessageResource } from '@/api/gateway/messages/resources/ChatMessageResource';
import type { ChatResource } from '@/api/gateway/messages/resources/ChatResource';
import type { ChatPreferenceResource } from '@/api/gateway/messages/resources/ChatPreferenceResource';
import { listenWhenVisible } from '@/modules/echo';
import { Lock } from '@/utils/locksUtils';

const watchUnreadMessagesDelay = parseInt(import.meta.env.MIX_CHATS_WATCH_UNREAD_MESSAGES_DELAY ?? '0', 10) * 1000;
const unwatchUnreadMessagesDelay = parseInt(import.meta.env.MIX_CHATS_UNWATCH_UNREAD_MESSAGES_DELAY ?? '0', 10) * 1000;

const types = {
    SET_CHAT: 'SET_CHAT',
    EDIT_CHAT: 'EDIT_CHAT',
    SET_CHATS: 'SET_CHATS',
    SET_TOTAL_UNREAD_MESSAGES: 'SET_TOTAL_UNREAD_MESSAGES',
    SET_TOTAL_UNREAD_MESSAGES_COUNT: 'SET_TOTAL_UNREAD_MESSAGES_COUNT',
    SET_IS_LOADING_CHATS: 'SET_IS_LOADING_CHATS',
    SET_HAS_LOADED_CHATS: 'SET_HAS_LOADED_CHATS',
    SET_MESSAGE: 'SET_MESSAGE',
    SET_MESSAGES: 'SET_MESSAGES',
    SET_CHAT_META: 'SET_CHAT_META',
    UPDATE_CHAT_BLOCK_STATUS: 'UPDATE_CHAT_BLOCK_STATUS',
    SET_PREFERENCES: 'SET_PREFERENCES',
    SET_PREFERENCES_LOADING: 'SET_PREFERENCES_LOADING',
};

interface ChatMeta {
    isLoadingMessages: boolean;
    isLoadingNewMessages: boolean;
    hasOlderMessages: boolean;
}

interface ChatState {
    isLoadingChats: boolean;
    hasLoadedChats: boolean;
    totalUnreadMessages: number;
    chats: {
        [chatId: number]: Readonly<ChatResource> | undefined;
    };
    chatMeta: {
        [chatId: number]: ChatMeta | undefined;
    };
    chatMessages: {
        [chatId: number]:
            | {
                  [messageId: number]: Readonly<ChatMessageResource> | undefined;
              }
            | undefined;
    };
    preferences: ChatPreferenceResource;
    isLoadingPreferences: boolean;
}

const state: ChatState = {
    chats: {},
    isLoadingChats: false,
    hasLoadedChats: false,
    totalUnreadMessages: 0,

    chatMeta: {},

    chatMessages: {},
    preferences: {} as ChatPreferenceResource,
    isLoadingPreferences: false,
};

enum BroadcastEvents {
    MessageReceived = '.message.received',
    MessageDeleted = '.message.deleted',
}

const getters: GetterTree<ChatState, unknown> = {
    chats: (state, getters) => {
        // we attach the currently stored last message to the chat, as the currently attached data might by out-of-sync
        const chatsWithLatestMessage = Object.values(state.chats).map(
            (chat) =>
                chat && {
                    ...chat,
                    last_message: getters.chatNewestUndeletedMessage(chat.id) || chat.last_message,
                },
        );
        return orderBy(chatsWithLatestMessage, ['last_message.created_at', 'updated_at'], ['desc', 'desc']);
    },
    chat: (state) => (id: number) => state.chats[id],
    messagesUnread: (state) => state.totalUnreadMessages,
    chatsAreLoading: (state) => state.isLoadingChats,
    hasLoadedChats: (state) => state.hasLoadedChats,
    // Numeric object keys are ordered by default. So we use this behavior to return an already ordered message list by created_at (same as id) ascending order
    chatMessages: (state) => (chatId: number) =>
        Object.values(state.chatMessages[chatId] as Record<number, ChatMessageResource>),
    chatMeta: (state) => (chatId: number) => state.chatMeta[chatId],
    chatNewestReceivedMessage: (state, getters) => (chatId: number, currentUserId: number) => {
        const messages = getters.chatMessages(chatId) as ChatMessageResource[];
        return findLast(messages, (msg) => msg.user_id !== currentUserId);
    },
    chatNewestUndeletedMessage: (state, getters) => (chatId: number) => {
        const messages = getters.chatMessages(chatId) as ChatMessageResource[];
        return findLast(messages, (msg) => !msg.deleted_at);
    },
    chatNewestMessage: (state, getters) => (chatId: number) => {
        const messages = getters.chatMessages(chatId) as ChatMessageResource[];
        return last(messages);
    },
    chatNewestMessageId: (state, getters) => (chatId: number) => {
        const message = getters.chatNewestMessage(chatId) as ChatMessageResource | null;
        return message?.id ?? null;
    },
    chatOldestMessageId: (state, getters) => (chatId: number) => {
        const messages = getters.chatMessages(chatId) as ChatMessageResource[];
        return messages.length ? messages[0].id : null;
    },
    preferencesAreLoading: (state) => state.isLoadingPreferences,
    preferences: (state) => state.preferences,
};

const mutations: MutationTree<ChatState> = {
    [types.SET_CHAT](state, chat: ChatResource) {
        state.chats[chat.id] = Object.freeze(chat);

        if (!state.chatMessages[chat.id]) {
            // init message storage
            state.chatMessages[chat.id] = {};
        }

        if (!state.chatMeta[chat.id]) {
            // init meta storage
            mutations[types.SET_CHAT_META](state, { chatId: chat.id });
        }
    },
    [types.EDIT_CHAT](state, data: Partial<ChatResource> & { id: number }) {
        if (!state.chats[data.id]) return;

        mutations[types.SET_CHAT](state, {
            ...state.chats[data.id],
            ...data,
        });
    },
    [types.SET_CHATS](state, chats: ChatResource[]) {
        chats.forEach((chat) => mutations[types.SET_CHAT](state, chat) as unknown);
    },
    [types.SET_IS_LOADING_CHATS](state, status: boolean) {
        state.isLoadingChats = status;
    },
    [types.SET_HAS_LOADED_CHATS](state, status: boolean) {
        state.hasLoadedChats = status;
    },
    [types.SET_MESSAGE](state, message: ChatMessageResource) {
        if (!state.chatMessages[message.chat_id]) return;
        (state.chatMessages[message.chat_id] as Record<number, Readonly<ChatMessageResource>>)[message.id] =
            Object.freeze(message);
    },
    [types.SET_MESSAGES](state, messages: ChatMessageResource[]) {
        messages.forEach((message) => mutations[types.SET_MESSAGE](state, message) as unknown);
    },
    [types.SET_CHAT_META](state, { chatId, ...meta }: Partial<ChatMeta> & { chatId: number }) {
        if (!state.chatMeta[chatId]) {
            state.chatMeta[chatId] = {
                isLoadingMessages: false,
                isLoadingNewMessages: false,
                hasOlderMessages: true,
            };
        }

        Object.assign(state.chatMeta[chatId] as object, meta);
    },
    [types.UPDATE_CHAT_BLOCK_STATUS](state, payload: { chatId: number; action: string }) {
        const chat = state.chats[payload.chatId];
        if (!chat) return;

        state.chats[payload.chatId] = Object.freeze({
            ...chat,
            is_blocked: payload.action === 'block' ? 1 : 0,
        });
    },
    [types.SET_TOTAL_UNREAD_MESSAGES_COUNT](state, count: number) {
        state.totalUnreadMessages = count;
    },
    [types.SET_TOTAL_UNREAD_MESSAGES](state, chats: ChatResource[]) {
        state.totalUnreadMessages = chats.reduce(
            (total, chat) => total + (!chat.is_blocked ? chat.unread_messages : 0),
            0,
        );
    },
    [types.SET_PREFERENCES_LOADING](state, status) {
        state.isLoadingPreferences = status;
    },
    [types.SET_PREFERENCES](state, payload) {
        state.preferences = payload;
    },
};

const actions: ActionTree<ChatState, unknown> = {
    incrementChatTotalUnreadMessagesCount({ commit, getters }, chatId: number) {
        const chat = getters.chat(chatId);
        if (!chat) return;

        commit(types.EDIT_CHAT, {
            id: chat.id,
            unread_messages: chat.unread_messages + 1,
        });
    },
    incrementTotalUnreadMessages({ getters, commit }) {
        commit(types.SET_TOTAL_UNREAD_MESSAGES_COUNT, getters.messagesUnread + 1);
    },
    /**
     * fetches and listens for unread message count changes.
     * Only one tab will listen at a time.
     * Any change will be synced across tabs via the vuex persist plugin.
     * @returns function to stop watching
     */
    listenToUnreadMessagesCountForUser({ dispatch }, payload: { userId: number; startDelay?: number }) {
        return listenWhenVisible(
            `private-messages.${payload.userId}`,
            BroadcastEvents.MessageReceived,
            () => dispatch('incrementTotalUnreadMessages'),
            {
                startDelay: payload.startDelay ?? watchUnreadMessagesDelay,
                endDelay: unwatchUnreadMessagesDelay,
                lock: Lock.LISTEN_UNREAD_MESSAGES_COUNT,
            },
        );
    },
    listenToMessagesForUser({ dispatch, commit }, payload: { userId: number; beforeStart?: () => Promise<void> }) {
        return listenWhenVisible(
            `private-messages.${payload.userId}`,
            [BroadcastEvents.MessageReceived, BroadcastEvents.MessageDeleted],
            (message: ChatMessageResource) => {
                if (message.deleted_at) {
                    commit(types.SET_MESSAGE, message);
                    return;
                }

                dispatch('incrementChatTotalUnreadMessagesCount', message.chat_id);
                dispatch('pushNewMessage', message);
            },
            {
                beforeStart: payload.beforeStart,
            },
        );
    },
    pushNewMessage({ commit, dispatch, getters }, message: ChatMessageResource) {
        if (!getters.chat(message.chat_id)) {
            dispatch('loadChats');
            return;
        }

        commit(types.SET_MESSAGE, message);
    },
    async loadChatForUser({ commit }, userid: number) {
        const { data } = await storeChat(userid);
        commit(types.SET_CHAT, data);
        return data;
    },
    async loadUnreadMessagesCount({ commit }) {
        // Get list of all locks to check if ChatIndex in another tab is already listening to unread messages
        const locks = await navigator.locks.query();
        if (locks.held?.some((lock) => lock.name === Lock.LISTEN_UNREAD_MESSAGES_COUNT)) {
            return false;
        }
        const { data } = await fetchUnreadMessages();
        commit(types.SET_TOTAL_UNREAD_MESSAGES_COUNT, data.data.count);
        return data.data.count as number;
    },
    loadChats({ getters, commit }) {
        if (getters.chatsAreLoading) return Promise.reject(new Error('Chats are already loading'));
        commit(types.SET_IS_LOADING_CHATS, true);

        return fetchChats()
            .then((response) => {
                commit(types.SET_CHATS, response.data.data);
                commit(types.SET_TOTAL_UNREAD_MESSAGES, response.data.data);
                commit(types.SET_HAS_LOADED_CHATS, true);
            })
            .finally(() => {
                commit(types.SET_IS_LOADING_CHATS, false);
            });
    },
    async sendMessage({ dispatch }, message: ChatMessageResource) {
        const { data } = await storeMessage(message);
        dispatch('pushNewMessage', data);

        return data;
    },
    async deleteMessage({ commit }, message: ChatMessageResource) {
        const { data } = await deleteMessage(message);

        // TODO: at this point in development, we don't yet get a response back and need to delete the message ouselves in the frontend
        commit(types.SET_MESSAGE, {
            ...message,
            deleted_at: new Date(),
            parent: null,
            parent_id: null,
            text: null,
            ...data,
        });
    },
    loadMessages({ getters, commit }, { chatId, oldestMessageId = null, newestMessageId = null }) {
        const chatMeta = getters.chatMeta(chatId);
        if (chatMeta?.isLoadingMessages) {
            return Promise.reject(new Error('An ongoing promise detected: chats.loadMessages()'));
        }

        commit(types.SET_CHAT_META, {
            chatId,
            isLoadingMessages: true,
        });

        return fetchMessages(chatId, oldestMessageId, newestMessageId)
            .then((response) => {
                commit(types.SET_MESSAGES, response.data.data);

                if (chatMeta.hasOlderMessages) {
                    // we only need to update `hasOlderMessages` as long as we don't know if there are more messages
                    commit(types.SET_CHAT_META, {
                        chatId,
                        hasOlderMessages: response.data.data.length !== 0,
                    });
                }
            })
            .finally(() => {
                commit(types.SET_CHAT_META, {
                    chatId,
                    isLoadingMessages: false,
                });
            });
    },
    async loadOlderMessages({ getters, dispatch }, { chatId }) {
        return dispatch('loadMessages', {
            chatId,
            oldestMessageId: getters.chatOldestMessageId(chatId),
        }) as ReturnType<typeof fetchMessages>;
    },
    async loadTillQuotedMessage({ getters, dispatch }, { chatId, quotedMessageId }) {
        if (getters.chatMeta(chatId)?.isLoadingMessages) {
            return Promise.reject(new Error('An ongoing promise detected: chats.loadTillQuotedMessage()'));
        }
        const oldestMessageId = getters.chatOldestMessageId(chatId);
        if (quotedMessageId >= oldestMessageId) return Promise.resolve();
        return dispatch('loadMessages', {
            chatId,
            oldestMessageId,
            newestMessageId: quotedMessageId,
        }) as ReturnType<typeof fetchMessages>;
    },
    async loadMessageUpdates({ getters, commit }, { chatId, timestamp }) {
        if (getters.chatMeta(chatId)?.isLoadingNewMessages) {
            return Promise.reject(new Error('An ongoing promise detected: chats.loadMessageUpdates()'));
        }

        commit(types.SET_CHAT_META, {
            chatId,
            isLoadingNewMessages: true,
        });

        return fetchNewMessages(chatId, timestamp)
            .then((response) => {
                commit(types.SET_MESSAGES, response.data.data);
            })
            .finally(() => {
                commit(types.SET_CHAT_META, {
                    chatId,
                    isLoadingNewMessages: false,
                });
            });
    },
    markChatAsRead({ commit, getters }, chatId: number) {
        const chat = getters.chat(chatId);
        if (!chat) return;

        const totalCount = getters.messagesUnread - chat.unread_messages;
        commit(types.SET_TOTAL_UNREAD_MESSAGES_COUNT, totalCount);
        commit(types.EDIT_CHAT, {
            id: chatId,
            unread_messages: 0,
        });
    },
    updateChatBlockStatus({ commit }, payload) {
        commit(types.UPDATE_CHAT_BLOCK_STATUS, payload);
    },
    async loadPreferences({ getters, commit }) {
        if (getters.preferencesAreLoading) return;
        commit(types.SET_PREFERENCES_LOADING, true);
        getPreferences()
            .then((response) => {
                commit(types.SET_PREFERENCES, response.data);
            })
            .finally(() => commit(types.SET_PREFERENCES_LOADING, false));
    },
    setPreferences({ commit }, settings) {
        setPreferences(settings).then((response) => {
            commit(types.SET_PREFERENCES, response.data);
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
