import type { EnumToUnion } from '@/types/misc';

export enum GtmEventName {
    REGISTRATION_START = 'gtm_registration_start',
    REGISTRATION_SIGNUP = 'gtm_registration_signup',
    REGISTRATION_CREATE_PROFILE_NAME = 'gtm_registration_create_profile_name',
    REGISTRATION_COMPLETED = 'gtm_registration_completed',
    REGISTRATION_EMAIL_VERIFIED = 'gtm_registration_email_verified',

    LOGIN_SUCCESS = 'gtm_user-login-success',
    LOGOUT = 'gtm_logout',

    JOIN_COURSE = 'gtm_joined_course',
    LEAVE_COURSE = 'gtm_leave_course',

    CREATE_POST_COURSE = 'gtm_create_post_course',
    CREATE_POST_GROUP = 'gtm_create_post_group',
    CREATE_POST_DOCUMENT = 'gtm_create_post_doc',
    CREATE_COMMENT_COURSE = 'gtm_comment_post_course',
    CREATE_COMMENT_GROUP = 'gtm_comment_post_group',
    CREATE_COMMENT_DOCUMENT = 'gtm_comment_doc',

    CREATE_DOCUMENT = 'gtm_create_file',
    CREATE_DOCUMENT_GUEST_UPLOAD = 'gtm_create_file_guest_upload',
    CREATE_FLASHCARDS = 'gtm_create_flashcard',
    CREATE_CONTENT = 'gtm_create_document',
    CREATE_FIRST_CONTENT = 'gtm_first_document',

    DOWNLOAD_DOCUMENT = 'gtm_download_document',
    VIEW_DOCUMENT = 'gtm_study_session_document_view',
    MANUAL_DOWNLOAD_DOCUMENT = 'gtm_study_session_document_download',

    STUDY_START_FLASHCARDS = 'gtm_study_session_flashcard_started',
    STUDY_FLASHCARDS = 'gtm_study_session_flashcard_view',

    SEARCH = 'gtm_search',

    LINK_OUT = 'gtm_link_out',
    SOCIAL_OUT = 'gtm_click_out_socials',
    APPSTORE_OUT = 'gtm_click_out_appstores',

    SUBSCRIPTION_CHECKOUT = 'gtm_subscription_checkout',
    SUBSCRIPTION_SUCCESS = 'gtm_subscription_success',

    CE_SUBMIT_COUNTRY = 'gtm_ce_submit_country',
    CE_SUGGEST_UNI = 'gtm_ce_suggest_new_university',
    CE_CHECK_POSITIONS = 'gtm_ce_check',
    CE_NO_VACANCY = 'gtm_ce_no_vacancy',
    CE_PIPELINE = 'gtm_ce_pipeline',
    CE_VACANCY = 'gtm_ce_vacancy',
    CE_APPLY = 'gtm_ce_apply',
    CE_REJECT = 'gtm_ce_reject',
    CE_ONBOARD = 'gtm_ce_onboard',
    CE_FIRST_UPLOAD = 'gtm_ce_first_upload',
    CE_SECOND_UPLOAD = 'gtm_ce_second_upload',
    CE_UPLOAD = 'gtm_ce_upload',
}

export enum GtmEventUserType {
    REGULAR = 'regular_user',
    GUEST = 'guest_user',
}

export enum GtmEventLoginStatus {
    LOGGED_IN = 'logged_in',
    LOGGED_OUT = 'not_logged_in',
}

export enum GtmEventPageType {
    HOME = 'home',
    COURSE = 'course',
    DOCUMENTS = 'documents',
    FLASHCARDS = 'flashcards',
    GROUP = 'group',
    SEARCH = 'search',
    UNIVERSITY = 'university',
    COMPANY = 'company',
    REWARDS = 'rewards',
    NEWSFEED = 'newsfeed',
    OTHER = 'other',
}

export interface GtmEvent {
    event: EnumToUnion<GtmEventName>;
    page_type?: GtmEventPageType;
    user_type?: GtmEventUserType;
    login_status?: GtmEventLoginStatus;
    friend_invite?: boolean;
    subscription_price?: number;
    subscription_currency?: string;
}

declare global {
    interface Window {
        /**
         * This variable will be updated through gtm once usercentrics
         * has been initialized and the consent has been saved.
         */
        sdConsent?: boolean;
        /**
         * This is where all events are pushed before the consent has been saved.
         * A gtm tag will push all events into `dataLayer` once consent is given.
         */
        preConsentDataLayer: GtmEvent[];

        dataLayer?: GtmEvent[];
    }
}

window.preConsentDataLayer = [];

export function pushGtmEvent(eventName: EnumToUnion<GtmEventName>, customEventData: Partial<GtmEvent> = {}) {
    const layer = window.sdConsent ? 'dataLayer' : 'preConsentDataLayer';

    window[layer]?.push({
        event: eventName,
        ...customEventData,
    });
}
