<template>
    <component
        :is="$store.getters['auth/isLoggedIn'] ? 'TopBarLogged' : 'TopBarGuest'"
        v-resize.immediate="syncHeightWithStore"
        :class="[position, 'top-0']"
    />
</template>

<script lang="ts">
import { defineAsyncComponent, type PropType } from 'vue';
import { useScreenSize } from '@/composables/screenSize';

export default {
    name: 'TheTopBar',

    components: {
        TopBarGuest: defineAsyncComponent(() => import('./TopBarGuest.vue')),
        TopBarLogged: defineAsyncComponent(() => import('./TopBarLogged.vue')),
    },
    props: {
        /**
         * Position behavior of the topbar.
         */
        position: {
            type: String as PropType<'sticky' | 'fixed'>,
            default: 'sticky',
        },
    },
    setup() {
        return {
            currentScreen: useScreenSize().current,
        };
    },
    watch: {
        currentScreen() {
            this.syncHeightWithStore(this.$el);
        },
    },
    beforeUnmount() {
        this.$store.commit('ui/SET_TOP_BAR_HEIGHT', 0);
    },
    methods: {
        syncHeightWithStore(el: HTMLElement) {
            this.$store.commit('ui/SET_TOP_BAR_HEIGHT', el.offsetHeight);
        },
    },
};
</script>
