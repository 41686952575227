import type { Component, ComponentPublicInstance, Plugin } from 'vue';
import type { ModalEvents, ModalProps, ModalSlots } from '@/mixins/modalMixin';
import ModalContainer from '@components/ModalContainer';
import Modal, { ModalActionTypes } from '@components/Modal';
import { createChildApp } from '@/helpers/vue';

/* eslint-disable @typescript-eslint/no-empty-object-type */
type ContainerInstance = ComponentPublicInstance<
    {},
    {},
    {},
    {},
    {
        closeAll: () => void;
        openModal: <ReturnType>(
            component: Component,
            props?: ModalProps,
            events?: ModalEvents<ReturnType>,
            slots?: ModalSlots,
        ) => void;
    }
>;
/* eslint-enable */

const data = {
    container: undefined as ContainerInstance | undefined,
};

/**
 * Opens modal and returns a promise which resolves when the modal is emits a `close` event
 * @param component - either a component instance or definition or a dynamic import.
 */
export async function openModal<ReturnType = unknown>(
    component: Component,
    props: ModalProps = {},
    events: ModalEvents<ReturnType> = {},
    slots: ModalSlots = {},
): Promise<ReturnType | undefined> {
    if (!data.container) {
        throw new Error('Modal-Plugin must be installed first!');
    }

    return new Promise((resolve) => {
        data.container?.openModal(
            component,
            props,
            {
                ...events,
                close(val?: ReturnType) {
                    events.close?.(val);
                    resolve(val);
                },
            },
            slots,
        );
    });
}

/**
 * @deprecated just use openModal instead
 */
export const openModalAsync = openModal;

/**
 * Opens an info modal with the provided props.
 * You can use the returned promise to listen for modal closing.
 */
export async function openInfoModal(props: ModalProps, callback = () => {}): Promise<undefined> {
    return openModal(
        Modal,
        {
            ...props,
            noCancel: true,
        },
        {
            close() {
                callback();
            },
        },
    );
}

/**
 * Opens a confirmation modal with provided props.
 * You can use the returned promise to listen for modal confirmation or cancellation.
 */
export async function openConfirmModal(props: ModalProps, confirm = () => {}, cancel = () => {}) {
    return openModal<boolean>(Modal, props, {
        close(val) {
            if (val) {
                confirm();
            } else {
                cancel();
            }
        },
    });
}

/**
 * Opens a confirm modal in danger variant.
 * Same signature as ´openConfirmModal´
 */
export async function openConfirmDangerModal(props: ModalProps, confirm = () => {}, cancel = () => {}) {
    return openConfirmModal({ ...props, actionType: ModalActionTypes.DANGER }, confirm, cancel);
}

export default {
    /**
     * Installs modal plugin
     */
    install(app) {
        // eslint-disable-next-line no-param-reassign
        app.config.globalProperties.$modal = {
            open: openModal,
            /** @deprecated use open instead */
            openAsync: openModalAsync,
            confirm: openConfirmModal,
            confirmDanger: openConfirmDangerModal,
            info: openInfoModal,
            close: () => data.container?.closeAll(),
        };

        let el = document.getElementById('modals');
        if (!el) {
            // create body element which we mount our modals into
            el = document.createElement('div');
            el.id = 'modals';
            document.body.appendChild(el);
        }

        // create separate vue app for our modals based on app
        const modalApp = createChildApp(app, ModalContainer);
        data.container = modalApp.mount(el) as ContainerInstance;
    },
} as Plugin;
