import route from '@router/route';
import { backend } from '.';

export function getCredits() {
    return backend.get(route('gamification.credits'));
}
export function getCoins() {
    return backend.get(route('gamification.coins'));
}
export function getFakeDoorStatus() {
    return backend.get(route('gamification.fake-door.status'));
}
export function getStats(userId) {
    return backend.get(
        route('profile.stats', {
            id: userId,
        }),
    );
}

export function getDocuments(userId, followed, page = 1) {
    return backend.get(
        route('profile.document.index', {
            id: userId,
            page,
            followed,
        }),
    );
}

export function getFlashcards(userId, followed, page = 1) {
    return backend.get(
        route('profile.flashcard.index', {
            id: userId,
            page,
            followed,
        }),
    );
}

export function updateProfile(payload) {
    return backend.put(route('profile.update'), payload);
}

export function updateEmail(payload) {
    return backend.post(route('profile.settings.email'), payload);
}

export function updatePassword(payload) {
    return backend.post(route('profile.settings.password'), payload);
}

export function updateNotificationSetting(type, id) {
    return backend.post(route('profile.settings.notification'), { type, id }).then((response) => response.data);
}

export function deleteProfilePicture() {
    return backend.delete(route('profile.picture.destroy'));
}

export function deleteAccount() {
    return backend.delete(route('profile.destroy')).then((response) => response.data);
}

export function retrieveGroupMemberships() {
    return backend.get(route('groups.sidebar')).then((response) => response.data);
}

export function retrieveUserChallenges() {
    return backend.get(route('challenges.index')).then((response) => {
        if (response.status === 204) {
            return null;
        }
        return response.data.data;
    });
}

export function finishUserChallenges() {
    return backend.post(route('challenges.finish')).then((response) => response.status === 200);
}
