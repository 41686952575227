import route from '@router/route';
import { backend } from '.';

export const JOBS_PRIORITY = {
    permanent: 'permanent',
    student: 'student',
};

export function postCompanyImpression(companyId) {
    return backend.post(route('api.career.companies.impressions.increment', companyId));
}

export function postTopCompanyClick(companyId) {
    return backend.post(route('api.career.companies.landing-clicks.increment', companyId));
}

export function postTopCompanyImpression(companyId) {
    return backend.post(route('api.career.companies.landing-impressions.increment', companyId));
}

export function postTopCompaniesView(companyIds = []) {
    const data = {
        company_ids: companyIds,
    };
    return backend.post(route('api.career.companies.landing-views.increment'), data);
}

export function getTopCompanies(page, limit) {
    return backend.get(route('company.index.top', { page, limit }));
}

export function retrieveFilterOptions() {
    return backend.get(route('company.index.filter')).then((response) => {
        return response.data;
    });
}

export function filterCompanies(filter, page, sessionToken) {
    return backend.get(route('company.filter', { ...filter, page, sessionToken }));
}

export function searchLocations(payload) {
    return backend.get(route('company.search.location', { search: payload }));
}
