import { readonly, ref, watchEffect } from 'vue';
import { once } from 'lodash-es';
import { useCookies } from '@vueuse/integrations/useCookies';
import { watchVisibility } from '@/helpers/visibility';
import { Cookie } from '@/utils/cookieUtils';

const COOKIE_NAME = Cookie.AUTH_ACCESS_TOKEN as string;

/**
 * Reactive access token composable that will update whenever the associated cookie changes
 */
export const useAccessToken = once(() => {
    const options = Object.freeze({
        path: '/',
        domain: `.${document.location.hostname}`,
    });
    const apiCookies = useCookies([COOKIE_NAME], { autoUpdateDependencies: true });
    const tokenRef = ref<string | undefined>(undefined);

    const syncToken = () => {
        tokenRef.value = apiCookies.get<string | undefined>(COOKIE_NAME);
    };

    // sync the token whenever the cookie changes
    watchEffect(syncToken);

    // sync the token whenever the page becomes visible again
    watchVisibility(syncToken);

    return {
        options,
        accessToken: readonly(tokenRef),
        // this composable updates the token automatically,
        // but you might find it useful to trigger a forced update to prevent race conditions.
        forceUpdate: syncToken,
        clear: () => {
            tokenRef.value = undefined;
            apiCookies.remove(COOKIE_NAME, options);
        },
    };
});
