import { captureMessage } from '@sentry/vue';
import { requestLockOrWait } from '@/helpers/locks';
import { timeout } from '@/helpers/misc';
import { HTTP_STATUS } from '@/utils/networkUtils';
import route from '@/router/route';
import { Lock } from '@/utils/locksUtils';
import { EventBus, EventBusEvent } from '@/plugins/eventBus';
// eslint-disable-next-line import/no-restricted-paths
import { useBackendHeaders } from '@/api/backend/index.axios';
import { useAccessToken } from '@/api/utils/composables/accessToken';
import { notifyInfo } from '@/components/FlashMessages';
import { i18n } from '@/plugins/i18n';
import { toRef, toValue } from 'vue';

/**
 * Handles a missing token error by prompting the user to login again.
 */
function handleMissingToken() {
    // prompt to login if access token no longer exists
    // Note: the server is also able to remove the AT & RT cookies through the response header fields
    notifyInfo(i18n.t('errors.expiredSession'), { key: 'expired-session' });
    EventBus.emit(EventBusEvent.CLEAR_USER_DATA);
    EventBus.emit(EventBusEvent.PROMPT_LOGIN);
}

/**
 * Initiates a token refresh across all browser tabs.
 * Calling it multiple times will result in waiting for the initial refresh to finish.
 * It will block any request from going out until the refresh is finished.
 *
 * If any error occurs, the user is prompted to login again.
 */
async function refreshAuthToken(): Promise<boolean> {
    const { accessToken, clear, forceUpdate } = useAccessToken();

    // Note: AT & RT cookies have the same lifetime (although the AT will expire sooner) and will always exist together.
    // If the AT cookie no longer exists, the RT cookie is also removed.
    // Meaning, we can only successfully refresh tokens if the AT cookie exists.
    // Other situations are artificially fabricated.
    if (!accessToken.value) {
        handleMissingToken();
        return false;
    }

    await requestLockOrWait(Lock.TOKEN_REFRESH, () =>
        navigator.locks.request(Lock.SEND_REQUEST, async () => {
            try {
                const { Authorization, ...headers } = toValue(toRef(useBackendHeaders()));

                const response = await fetch(route('auth-provider.refresh'), {
                    cache: 'no-store',
                    headers,
                    keepalive: true,
                    // @ts-ignore the priority spec is too new?
                    priority: 'high',
                });

                // ensure the access token is up to date as the server modifies it
                forceUpdate();

                if (!response.ok) {
                    // we clear the cookies manually as well in case of an unknown server request that prevents us to perform token refreshes reliably
                    clear();
                    // TODO: remove this capture message later.
                    // It is currently used to track the number of times the token refresh fails to understand if our improvements worked.
                    // But we can expect some token refreshes to always fail:
                    // - when user re-opens the browser with a cached version, but session cookies were deleted
                    // - user got banned
                    captureMessage('Token refresh failed', {
                        level: 'warning',
                        tags: {
                            status: response.status,
                        },
                    });
                    EventBus.emit(EventBusEvent.TRACK_TOKEN_REFRESH_FAILED);
                }

                // we add a small timeout so that token changes have a bit of time to update everywhere
                await timeout(10);
            } catch (e) {
                // request did not reach the server
                // by silently failing, we can re-trigger the refresh token process with the next failing request
            }
        }),
    );

    // This condition is relevant for all processes that awaited the lock.
    // When the token refresh fails for a separate process, other processes should also be handled properly here
    if (!accessToken.value) {
        handleMissingToken();
        return false;
    }

    return true;
}

/**
 * Only use this function for backend responses as gateway responses behave differently.
 */
function shouldRefreshAuthTokenFor(status: number, url?: string) {
    return status === HTTP_STATUS.UNAUTHORIZED && url !== route('auth-provider.refresh').toString();
}

export default {
    refreshAuthToken,
    shouldRefreshAuthTokenFor,
};
