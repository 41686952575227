import axios from 'axios';
import { assignCancelToken } from '@/api/utils';
import type { SearchStudyMaterialDocumentResource } from '@/api/gateway/legacy-api/resources/SearchStudyMaterialDocumentResource';
import type { SearchStudyMaterialFlashcardResource } from '@/api/gateway/legacy-api/resources/SearchStudyMaterialFlashcardResource';
import type { EmptyObject, EnumToUnion } from '@/types/misc';
import type { InstitutionType } from '@/api/gateway/legacy-api/resources/UniversityResource';
import { gateway } from '..';
import type { SearchCourseResource } from './resources/SearchCourseResource';
import type { SearchSchoolResource } from './resources/SearchSchoolResource';
import type { SearchUniversityResource } from './resources/SearchUniversityResource';
import type { SearchResultCollection } from './resources/SearchResultCollection';

enum SearchContext {
    COURSES = 'courses',
    DISCUSSIONS = 'discussions',
    DOCUMENTS = 'documents',
    STUDY_MATERIALS = 'study-materials',
    UNIVERSITIES = 'universities',
    SCHOOLS = 'schools',
}

export interface BaseSearchParams {
    term?: string;
    highlight?: boolean;
    page?: number;
    per_page?: number;
    sort?: string;
}

export interface CourseSearchParams extends BaseSearchParams {
    university_id?: number[];
    institution_type?: InstitutionType;
    course_id?: number[];
    grades?: number[];
}

export interface SchoolSearchParams extends BaseSearchParams {
    university_id: number[];
}

export interface UniversitySearchParams extends BaseSearchParams {
    institution_type?: number;
}

export enum StudyMaterialsSortType {
    BestMatch = 'best_match',
    Downloads = 'downloads',
    Latest = 'latest',
    Rating = 'rating',
    VoteRating = 'rating_avg_vote',
}

export interface StudyMaterialsSearchParams extends BaseSearchParams {
    term?: string;
    university_id?: number;
    course_id?: number;
    language_ids?: number[];
    type_ids?: number[];
    has_ai_content?: boolean;
    min_academic_year?: number;
    max_academic_year?: number;
    reference_time?: Date;
    sort?: StudyMaterialsSortType;
}

type SearchParamsByContext = {
    [SearchContext.COURSES]: CourseSearchParams;
    [SearchContext.DISCUSSIONS]: BaseSearchParams;
    [SearchContext.DOCUMENTS]: BaseSearchParams;
    [SearchContext.STUDY_MATERIALS]: StudyMaterialsSearchParams;
    [SearchContext.UNIVERSITIES]: UniversitySearchParams;
    [SearchContext.SCHOOLS]: SchoolSearchParams;
};

export interface BaseSearchResponse {
    success: true;
    per_page: number;
    reference_time: Date;
    courses: EmptyObject;
    discussions: EmptyObject;
    documents: EmptyObject;
    study_materials: EmptyObject;
    universities: EmptyObject;
    schools: EmptyObject;
}

export type CourseSearchResponse = BaseSearchResponse & {
    courses: SearchResultCollection<SearchCourseResource>;
};

export type DiscussionSearchResponse = BaseSearchResponse & {
    discussions: unknown; // TODO: Implement the proper structure...
};

export type DocumentSearchResponse = BaseSearchResponse & {
    documents: SearchResultCollection<SearchUniversityResource, 'semester_id' | 'file_content_type'>;
};

export type StudyMaterialSearchResponse = BaseSearchResponse & {
    study_materials: SearchResultCollection<SearchStudyMaterialDocumentResource | SearchStudyMaterialFlashcardResource>;
};

export type UniversitySearchResponse = BaseSearchResponse & {
    universities: SearchResultCollection<SearchUniversityResource>;
};

export type SchoolSearchResponse = BaseSearchResponse & {
    schools: SearchResultCollection<SearchSchoolResource>;
};

export type SearchResponse<C extends SearchContext> = C extends SearchContext.COURSES
    ? CourseSearchResponse
    : C extends SearchContext.DISCUSSIONS
      ? DiscussionSearchResponse
      : C extends SearchContext.DOCUMENTS
        ? DocumentSearchResponse
        : C extends SearchContext.STUDY_MATERIALS
          ? StudyMaterialSearchResponse
          : C extends SearchContext.UNIVERSITIES
            ? UniversitySearchResponse
            : C extends SearchContext.SCHOOLS
              ? SchoolSearchResponse
              : BaseSearchResponse;

export function search<C extends SearchContext>(context: EnumToUnion<C>, params?: SearchParamsByContext[C]) {
    const cancelToken = axios.CancelToken.source();
    const promise = gateway.get<SearchResponse<C>>(`legacy-api/v1/search/${context}`, {
        params,
        cancelToken: cancelToken.token,
        ...(context === SearchContext.SCHOOLS ? { headers: { 'X-SD-Route-Version': 2 } } : {}),
    });
    return assignCancelToken(promise, cancelToken);
}

export function searchStudyMaterials(params?: StudyMaterialsSearchParams) {
    return search('study-materials', params);
}

export function searchCourses(params: CourseSearchParams = { university_id: [], per_page: 9, page: 1 }) {
    return search('courses', params);
}

export function searchUniversities(params?: UniversitySearchParams) {
    return search('universities', params);
}
