/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Plugin } from 'vue';
import { TinyEmitter } from 'tiny-emitter';
import type { FlashMessageOptions } from '@/components/FlashMessages/types';
import type { DropzoneFile } from 'dropzone-vue3';
import type { UploadFile } from '@/views/UploadIndex/types';

/**
 * Colelction of events that are being emitted throuhg our global event bus
 * @todo define your event here
 */
export enum EventBusEvent {
    PROMPT_LOGIN = 'prompt-login',
    PROMPT_SIGNUP = 'prompt-signup',

    NOTIFY_INFO = 'notify-info',
    NOTIFY_SUCCESS = 'notify-success',
    NOTIFY_ERROR = 'notify-error',
    FILE_ADDED = 'file-added',
    UPLOAD_FILES = 'process-upload',
    UPLOAD_MORE_FILES = 'upload-more-files',
    UPDATE_ALL_FILES = 'update-all-files',
    DELETE_FILE = 'delete-file',
    QUEST_CLAIMED = 'quest-claimed',

    FLASHCARD_FINISH_STUDY = 'flashcard-finish-study',
    FLASHCARD_LEAVE_STUDY = 'flashcard-leave-study',
    FLASHCARD_RESTART_STUDY = 'flashcard-restart-study',
    FLASHCARD_RE_STUDY = 'flashcard-re-study',
    FLASHCARD_OPEN_STUDY_SETTINGS_DIALOG = 'flashcard-study-settings-dialog',

    CLEAR_USER_DATA = 'clear-user-data',
    TRACK_TOKEN_REFRESH_FAILED = 'track-token-refresh-failed',
}

/**
 * This types provides improved type checking for our event bus event
 * so that if you use `bus.emit('event-name', param1, param2)` you have type checks for the params in `bus.on('event-name', (param1, param2) => {})`
 * @todo define your custom event arguments here
 */
type CustomEventArguments = {
    [EventBusEvent.NOTIFY_INFO]: [string, FlashMessageOptions];
    [EventBusEvent.NOTIFY_SUCCESS]: [string, FlashMessageOptions];
    [EventBusEvent.NOTIFY_ERROR]: [string, FlashMessageOptions];
    [EventBusEvent.QUEST_CLAIMED]: [{ reward: number }];
    [EventBusEvent.FILE_ADDED]: [DropzoneFile];
    [EventBusEvent.DELETE_FILE]: [number];
    [EventBusEvent.UPLOAD_MORE_FILES]: [];
    [EventBusEvent.UPLOAD_FILES]: [];
    [EventBusEvent.CLEAR_USER_DATA]: [];
    [EventBusEvent.UPDATE_ALL_FILES]: [
        UploadFile,
        {
            visibility: boolean;
            self_made: boolean;
            course: boolean;
            type: boolean;
            semester: boolean;
            professor: boolean;
            language_id: boolean;
            description: boolean;
        },
    ];
};

// this type provides an empty arguments fallback for types that are not defined in CustomEventArguments
type EventArguments = CustomEventArguments & {
    [key in Exclude<EventBusEvent, keyof CustomEventArguments>]: [];
};

/**
 * Custom interface with improved type checking for our event bus events
 */
interface EventBusInterface extends TinyEmitter {
    on<E extends EventBusEvent>(event: E, callback: (...args: EventArguments[E]) => void, ctx?: any): this;
    once<E extends EventBusEvent>(event: E, callback: (...args: EventArguments[E]) => void, ctx?: any): this;
    off<E extends EventBusEvent>(event: E, callback?: (...args: EventArguments[E]) => void): this;
    emit<E extends EventBusEvent>(event: E, ...args: EventArguments[E]): this;
}

export const EventBus = new TinyEmitter() as EventBusInterface;

// add type definition inside vue components
declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $bus: typeof EventBus;
    }
}

// add global window type
declare global {
    interface Window {
        /**
         * This is a legacy pattern and therefore should not be used anymore.
         * The global event bus is still used in some flashcards components.
         *
         * @deprecated import from @/plugins/bus instead */
        bus: typeof EventBus;
    }
}

export default {
    install(app) {
        // The global event bus is still used in some flashcards components.
        // It's a legacy pattern and therefore should not be used anymore.
        // TODO: remove eventually.
        window.bus = EventBus;

        // eslint-disable-next-line no-param-reassign
        app.config.globalProperties.$bus = EventBus;
    },
} as Plugin;
