import { getTopCompanies, filterCompanies } from '@/api/backend/company';
import { getArticles, getProfile } from '@/api/sanity';
import { remove, sortBy } from 'lodash-es';
import dayjs from 'dayjs';

const types = {
    SET_COMPANY_DATA: 'SET_COMPANY_DATA',
    SET_COMPANY_ARTICLES_DATA: 'SET_COMPANY_ARTICLES_DATA',
    SET_COMPANY_PROFILE_DATA: 'SET_COMPANY_PROFILE_DATA',
    UPDATE_TOP_COMPANIES_LIST: 'UPDATE_TOP_COMPANIES_LIST',
    UPDATE_TOP_COMPANIES_PROGRESS: 'UPDATE_TOP_COMPANIES_PROGRESS',
    UPDATE_COMPANY_LIST: 'UPDATE_COMPANY_LIST',
    UPDATE_COMPANY_PAGINATION_INFO: 'UPDATE_COMPANY_PAGINATION_INFO',
    UPDATE_FILTER_REQUEST_PROGRESS: 'UPDATE_FILTER_REQUEST_PROGRESS',
    UPDATE_SESSION_TOKEN: 'UPDATE_SESSION_TOKEN',
};

const state = {
    sessionToken: Math.floor(Math.random() * 1000),
    company: {},
    topCompanies: {
        list: [],
        progress: false,
    },
    filterRequestInProgress: false,
    companyList: [],
    paginationInfo: {
        current_page: 0,
        from: 0,
        last_page: 0,
        new_query_url: '',
        path: '',
        per_page: 0,
        to: 0,
        total: 0,
    },
};

function isFutureEvent(event) {
    const date = dayjs(event.eventDate);
    const now = new Date();
    return date.isAfter(now) || (event.allDay && date.isSame(now, 'day'));
}

function isOngoingEvent(event) {
    if (event.eventEndDate) {
        const date = dayjs(event.eventEndDate);
        const now = new Date();
        return date.isAfter(now) || (event.allDay && date.isSame(now, 'day'));
    }

    return false;
}

const mutations = {
    [types.UPDATE_TOP_COMPANIES_LIST](state, payload) {
        state.topCompanies.list = payload;
    },
    [types.UPDATE_TOP_COMPANIES_PROGRESS](state, payload) {
        state.topCompanies.progress = payload;
    },
    [types.SET_COMPANY_DATA](state, payload) {
        state.company = { ...payload, ...state.company };
    },
    // data coming from Sanity.io
    [types.SET_COMPANY_PROFILE_DATA](state, payload) {
        const { links } = payload;

        const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        const videos = payload.videos
            ? payload.videos.map((video) => {
                  const match = video.url.match(regExp);
                  const picture =
                      match && match[7].length === 11 ? `https://i.ytimg.com/vi/${match[7]}/hqdefault.jpg` : null;
                  return { ...video, ...{ picture } };
              }) || []
            : [];

        state.company = { ...{ videos }, ...{ links }, ...state.company };
    },
    // data coming from Sanity.io
    [types.SET_COMPANY_ARTICLES_DATA](state, { articles }) {
        state.company = { ...{ articles }, ...state.company };
    },
    [types.UPDATE_COMPANY_LIST](state, payload) {
        state.companyList = payload;
    },
    [types.UPDATE_FILTER_REQUEST_PROGRESS](state, payload) {
        state.filterRequestInProgress = payload;
    },
    [types.UPDATE_COMPANY_PAGINATION_INFO](state, payload) {
        state.paginationInfo = payload;
    },
    [types.UPDATE_SESSION_TOKEN](state) {
        state.sessionToken = Math.floor(Math.random() * 1000);
    },
};

const actions = {
    retrieveProfile({ commit }, companyId) {
        return getProfile(companyId).then((response) => {
            // maybe in the future i could manage to create a GraphQL query that only returns the specific company?
            const [company] = response.data.data.allCompany;
            const { links, videos } = company || { links: null, videos: null };
            commit('SET_COMPANY_PROFILE_DATA', { links, videos });
        });
    },
    retrieveArticles({ commit }, companyId) {
        return getArticles(companyId).then((response) => {
            const articles = response.data.data.allArticle || [];
            remove(articles, (article) => {
                // remove all past events. (Dont remove ongoing events)
                if (article.eventDate) {
                    return !isFutureEvent(article) && !isOngoingEvent(article);
                }
                return false;
            });

            commit('SET_COMPANY_ARTICLES_DATA', {
                // sort events so that highlighted events show first
                articles: sortBy(articles, [(article) => article.highlighted !== true, 'eventDate']),
            });
        });
    },

    setCompanyData({ commit }, payload) {
        commit(types.SET_COMPANY_DATA, payload);
    },
    retrieveTopCompanies({ commit }, { page, limit }) {
        commit('UPDATE_TOP_COMPANIES_PROGRESS', true);
        return getTopCompanies(page, limit)
            .then((response) => commit('UPDATE_TOP_COMPANIES_LIST', response.data.data))
            .finally(() => commit('UPDATE_TOP_COMPANIES_PROGRESS', false));
    },
    filterCompanies({ commit, state }, { filter, page }) {
        commit('UPDATE_FILTER_REQUEST_PROGRESS', true);

        return filterCompanies(filter, page, state.sessionToken)
            .then((response) => {
                commit('UPDATE_COMPANY_LIST', response.data.data);
                commit('UPDATE_COMPANY_PAGINATION_INFO', response.data.meta);
            })
            .finally(() => commit('UPDATE_FILTER_REQUEST_PROGRESS', false));
    },
    updateSessionToken({ commit }) {
        commit(types.UPDATE_SESSION_TOKEN);
    },
};

const getters = {
    currentCompany: (state) => state.company,
    companyList: (state) => state.companyList,
    paginationInfo: (state) => state.paginationInfo,
    filterRequestInProgress: (state) => state.filterRequestInProgress,
    topCompaniesList: (state) => (state.topCompanies.list !== undefined ? state.topCompanies.list : []),
    topCompaniesProgress: (state) => (state.topCompanies.progress !== undefined ? state.topCompanies.progress : false),
    showIntroSection: (state) => {
        return (
            state.company &&
            state.company.content &&
            state.company.content.text_intro &&
            state.company.content.text_intro.length > 0
        );
    },
    showAboutUsSection: (state) => {
        return (
            state.company &&
            state.company.content &&
            state.company.content.text_about_us &&
            state.company.content.text_about_us.length > 0
        );
    },
    showCareerSection: (state) => {
        return (
            (state.company &&
                state.company.content &&
                state.company.content.text_career &&
                state.company.content.text_career.length > 0) ||
            (state.company.links && state.company.links.length > 0)
        );
    },
    showArticlesSection: (state) => {
        return (
            (state.company &&
                state.company.content &&
                state.company.content.text_articles &&
                state.company.content.text_articles.length > 0) ||
            (state.company.articles && state.company.articles.length > 0)
        );
    },
    showVideosSection: (state) => {
        return (
            (state.company &&
                state.company.content &&
                state.company.content.text_videos &&
                state.company.content.text_videos.length > 0) ||
            (state.company.videos && state.company.videos.length > 0)
        );
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
