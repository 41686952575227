import type { AxiosError } from 'axios';
import axios from 'axios';
import { i18n } from '@/plugins/i18n';
import { HTTP_STATUS } from '@/utils/networkUtils';
import { notifyError } from '@/components/FlashMessages';
import type { AnyApiErrorResponse } from '@/api/types';

/**
 * Determines the error key for some error types to easily group them.
 */
function getErrorKey(error: AxiosError): string | undefined {
    switch (error.response?.status) {
        case HTTP_STATUS.NETWORK_ERROR:
            return 'network-error';
        case HTTP_STATUS.MAINTENANCE:
            return 'maintenance';
        default:
            return undefined;
    }
}

/**
 * Indicates whether we should report an error with given http status to our error logging
 */
function shouldReportHttpStatus(error: AxiosError): boolean {
    switch (error?.response?.status) {
        // network errors have a network status of 'null'
        case HTTP_STATUS.NETWORK_ERROR:
        case HTTP_STATUS.TOO_MANY_REQUESTS:
            return false;
        default:
            return true;
    }
}

/**
 * Creates flash message for axios error
 */
function displayError(error: AxiosError<AnyApiErrorResponse>): void {
    const data = error.response?.data;

    // error is more specific for request validations, so we should process that first
    // Otherwise, error and message are somewhat used interchangeably
    const content =
        (data &&
            (('error' in data &&
                (typeof data.error === 'object'
                    ? // new error format
                      data.error.error_user_msg || data.error.message
                    : // old  error message as fallback
                      data.error)) ||
                ('message' in data && data.message) ||
                ('msg' in data && data.msg))) ||
        i18n.t('errors.unexpectedError');

    notifyError(content, { key: getErrorKey(error) });
}

/**
 * Handles unhandled axios errors and invokes flash messages.
 * @param notifyUser - whether the user should receive a flash message for the error
 * @returns true if error was handled and doesn't need to be processed further
 */
export function handleAxiosError(error: unknown, notifyUser = false): boolean {
    if (!axios.isAxiosError(error)) return false;

    // don't further process cancelled/aborted requests
    if (axios.isCancel(error)) return true;

    if ((error as AxiosError).ignore) return true;

    if (notifyUser || !(error as AxiosError).mute) {
        displayError(error);
    }

    return !shouldReportHttpStatus(error);
}

/**
 * Registers global axios error handler that catches all unhandled responses
 */
export function registerGlobalFallbackErrorHandler() {
    const originalHandler = window.onunhandledrejection;

    window.onunhandledrejection = (event) => {
        if (handleAxiosError(event.reason, true)) {
            event.stopImmediatePropagation();
            event.preventDefault();
        }

        if (event.bubbles && originalHandler) {
            originalHandler.call(window, event);
        }
    };
}
