<template>
    <div class="w-full relative">
        <div
            ref="primarySlotContainer"
            :class="['absolute', primarySlotOrientation]"
            :style="primarySlotContainerStyle"
        >
            <slot name="primary"></slot>
        </div>
        <div
            ref="secondarySlotContainer"
            :class="['absolute', secondarySlotOrientation]"
            :style="secondarySlotContainerStyle"
        >
            <slot name="secondary"></slot>
        </div>
        <textarea
            v-if="staticHeight"
            v-bind="{ ...$attrs, ...textareaProps }"
        ></textarea>
        <textarea
            v-else
            v-dynamic-height
            v-bind="{ ...$attrs, ...textareaProps }"
        ></textarea>
    </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';

export default defineComponent({
    name: 'InputTextarea',
    inheritAttrs: false,
    props: {
        /**
         * The input value.
         */
        modelValue: {
            type: String,
            default: '',
        },
        /**
         * The validation state;
         * true: valid, false: invalid, null: neutral
         */
        state: {
            type: Boolean as PropType<boolean | null>,
            default: null,
        },
        /**
         * The number of rows. This has no effect unless static height is enabled.
         */
        rows: {
            type: Number,
            default: 4,
        },
        /**
         * Whether or not the height is to be adjusted automatically.
         */
        staticHeight: {
            type: Boolean,
            default: false,
        },
        /**
         * Disabling the resize.
         */
        noResize: {
            type: Boolean,
            default: false,
        },
        /**
         * The maximum number of characters allowed.
         */
        maxLength: {
            type: Number,
            default: null,
        },
        /**
         * Whether or not the input content should be stacked on top of the slot content.
         */
        stacked: {
            type: Boolean,
            default: false,
        },
    },
    emits: {
        'update:modelValue': (val: string) => true, // eslint-disable-line
        input: (val: string) => true, // eslint-disable-line
    },
    setup() {
        return {
            observer: undefined as MutationObserver | undefined,
        };
    },
    data() {
        return {
            primarySlotRect: undefined as DOMRect | undefined,
            secondarySlotRect: undefined as DOMRect | undefined,
            /**
             * This represents the width of the custom scrollbar in the
             * textarea which is set to 12px.
             *
             * @see resources/sass/_scrollbars.scss
             */
            slotContainerMargin: 12,
        };
    },
    computed: {
        textareaProps() {
            return {
                ref: 'input',
                value: this.modelValue,
                class: this.textareaClass,
                style: this.textareaStyle,
                rows: this.rows,
                maxlength: this.maxLength,
                onInput: (payload: Event): void => this.onInput((payload.target as HTMLInputElement).value) as void,
            };
        },
        textareaClass() {
            return [
                this.$attrs.class,
                'custom-scrollbar',
                {
                    success: this.state,
                    error: this.state === false,
                    'resize-none': this.noResize,
                },
            ];
        },
        textareaStyle(): Record<string, string | number> {
            return {
                ...(this.$attrs.style as Record<string, string | number>),
                ...this.textareaPadding,
            };
        },
        textareaPadding(): Record<string, string | number> {
            const slotsRectMaxHeight = Math.max(this.primarySlotRect?.height || 0, this.secondarySlotRect?.height || 0);
            const slotsRectMaxWidth = Math.max(this.primarySlotRect?.width || 0, this.secondarySlotRect?.width || 0);
            if (this.stacked)
                return {
                    'padding-bottom': `${slotsRectMaxHeight + this.slotContainerMargin}px`,
                    'scroll-padding-bottom': `${slotsRectMaxHeight + this.slotContainerMargin}px`,
                };
            return {
                'padding-right': `${slotsRectMaxWidth + this.slotContainerMargin}px`,
            };
        },
        primarySlotContainerStyle(): string {
            const hOrientation = this.stacked ? 'left' : 'right';
            const vOrientation = this.stacked ? 'bottom' : 'top';
            return `margin-${hOrientation}: ${this.slotContainerMargin}px; margin-${vOrientation}: ${this.slotContainerMargin}px`;
        },
        primarySlotOrientation(): string {
            return this.stacked ? 'bottom-0' : 'right-0';
        },
        secondarySlotContainerStyle(): string {
            return `margin-right: ${this.slotContainerMargin}px; margin-bottom: ${this.slotContainerMargin}px`;
        },
        secondarySlotOrientation(): string {
            return 'right-0 bottom-0';
        },
    },
    watch: {
        stacked() {
            this.getSlotsRect();
        },
    },
    mounted() {
        this.getSlotsRect();
        this.observer = new MutationObserver(this.getSlotsRect);
        this.observer.observe(this.$refs.primarySlotContainer as Element, { childList: true, subtree: true });
        this.observer.observe(this.$refs.secondarySlotContainer as Element, { childList: true, subtree: true });
    },
    beforeUnmount() {
        this.observer?.disconnect();
    },
    methods: {
        focus(): void {
            (this.$refs.input as HTMLInputElement).focus();
        },
        onInput(val: string): void {
            this.$emit('input', val);
            this.$emit('update:modelValue', val);
        },
        getSlotsRect(): void {
            const primarySlotContainer = this.$refs.primarySlotContainer as Element;
            const secondarySlotContainer = this.$refs.secondarySlotContainer as Element;
            this.primarySlotRect = primarySlotContainer.getBoundingClientRect();
            this.secondarySlotRect = secondarySlotContainer.getBoundingClientRect();
        },
    },
});
</script>

<style lang="scss" scoped>
textarea {
    --trackColor: theme('colors.slate.50');
    margin-bottom: -7px;

    &::-webkit-scrollbar-track {
        @apply rounded-lg;
    }
}
</style>
