import route from '@router/route';

function sendData(baseUrl: string, payload: object) {
    const token = import.meta.env.MIX_SD_LAKE_SERVICE_API_TOKEN;
    const url = new URL(baseUrl);
    const params = new URLSearchParams({ token });
    url.search = params.toString();

    navigator.sendBeacon(url.href, JSON.stringify(payload));
}

/**
 * Stores track event data.
 *
 * @param {object} payload - The data to be stored.
 * @return void
 */
export function storeTrackEventData(payload: object): void {
    if (import.meta.env.MIX_SD_LAKE_SERVICE_DEBUG === 'true') {
        return;
    }

    sendData(route('external.lake.events'), payload);
}

/**
 * @deprecated OLD TRACKING SYSTEM
 * DO NOT USE ANYMORE
 */
export function storeTrackingData(payload: object): void {
    sendData(route('external.lake.tracking'), payload);
}
