<template>
    <!--
        triggered on click
        @event click
    -->
    <Link
        :class="[
            'menu-item  flex items-center cursor-pointer select-none hover:bg-blue-100 active:bg-blue-200 p-3 text-slate-600',
            $attrs.class,
        ]"
        v-bind="$attrs"
        @click="$emit('click', $event)"
    >
        <i
            v-if="icon"
            :class="['icon', `icon-${icon}`]"
            class="mr-3"
        ></i>
        <span class="text-base font-normal leading-normal whitespace-nowrap">
            <!-- @slot custom label -->
            <slot>{{ label }}</slot>
        </span>
    </Link>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Link from '@components/Link';

/**
 * This component visualizes a single menu item.
 * It utilizes the `Link` component to proivde navigation support, so you can use the `to` or `href` prop.
 */
export default defineComponent({
    name: 'MenuItem',
    components: {
        Link,
    },
    inheritAttrs: false,
    props: {
        /**
         * label of the item
         */
        label: {
            type: String,
            default: '',
        },
        /**
         * icon of the item
         * @example home
         */
        icon: {
            type: String,
            default: null,
            validator: (val: string) => !val.includes(' '),
        },
    },
    emits: ['click'],
});
</script>
