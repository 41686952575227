import store from '@/store';
import { cookies, Cookie } from '@/utils/cookieUtils';
import type { SentryEventProcessor } from './type';

/** enriches sentry event data with user data */
const enrichUserDataProcessor: SentryEventProcessor = (event) => {
    /* eslint-disable no-param-reassign */
    const user = store.getters['auth/user'];
    const is_pupil = user?.is_pupil || false;
    const is_premium = user?.is_premium || false;
    const is_admin = store.getters['auth/isAdmin'];
    const is_verified = store.getters['auth/isVerified'];

    const adBlockCookie = cookies.get(Cookie.AD_BLOCK_USED);
    event.tags = {
        ...(event.tags || {}),
        logged_in: !!user,
        is_pupil,
        is_premium,
        is_admin,
        is_verified,
        ...(adBlockCookie !== undefined ? { has_ad_blocker: !!adBlockCookie } : {}),
        screen_size: store.getters['ui/screenSize'],
        locale: store.getters['ui/getLocale'],
    };
    if (user) {
        // see: https://develop.sentry.dev/sdk/event-payloads/user/
        event.user = {
            id: user.userid,
            ip_address: '{{auto}}',
            is_admin,
            is_verified,
            is_pupil,
            is_premium,
        };
    }

    return event;
    /* eslint-enable no-param-reassign */
};

export default enrichUserDataProcessor;
