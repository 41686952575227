import AssetCdnImage from '@components/AssetCdnImage';
import Btn from '@components/Btn';
import BtnCta from '@components/BtnCta';
import BtnLink from '@components/BtnLink';
import Link from '@components/Link';
import InputCheckbox from '@components/InputCheckbox';
import InputContainer from '@/components/InputContainer';
import InputField from '@components/InputField';
import InputTextarea from '@/components/InputTextarea';
import Spinner from '@components/Spinner';

/**
 * Global resuable components
 */
export default {
    AssetCdnImage,
    Btn,
    BtnCta,
    BtnLink,
    Link,
    InputCheckbox,
    InputContainer,
    InputField,
    InputTextarea,
    Spinner,
};
