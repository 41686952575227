import { createPinia } from 'pinia';
import { createPersistedState } from 'pinia-plugin-persistedstate';
import { LocalStorageKeys } from '@/utils/localStorageUtils';

const pinia = createPinia();
pinia.use(
    createPersistedState({
        key: () => LocalStorageKeys.PINIA as string,
    }),
);

export default pinia;
