import { defineStore } from 'pinia';
import { getMyself } from '@/api/gateway/legacy-api/user';
import type { UserResource } from '@/api/gateway/legacy-api/resources/UserResource';
import { LocalStorageKeys, removeFromLocalStorage } from '@/utils/localStorageUtils';

const ALLOWED_KEYS = [
    'userid',
    'is_ai_chatbot_enabled',
];

export const useUserStore = defineStore('user', {
    persist: true,
    state: () => ({
        user: undefined as Partial<UserResource> | undefined,
    }),
    getters: {
        isUserLoaded: (state) => !!state.user?.userid,
        canSeeAiChat: (state) => !!state.user?.is_ai_chatbot_enabled,
        userId: (state) => state.user?.userid,
    },
    actions: {
        async loadUserData(forceUpdate = false) {
            if (this.isUserLoaded && !forceUpdate) return;

            const response = await getMyself();
            this.user = Object.entries(response).reduce((acc, [key, value]) => {
                if (ALLOWED_KEYS.includes(key)) {
                    return { ...acc, [key]: value };
                }
                return acc;
            }, {} as Partial<UserResource>);
        },
        logout() {
            this.$reset();
            removeFromLocalStorage(LocalStorageKeys.PINIA, 'user');
        },
    },
});
