import { isTruthy } from '@/helpers/primitives';
import type { Falsy } from '@/types/misc';
import { difference, chunk } from 'lodash-es';

/**
 * Toggles element in array
 * @param element
 * @param array
 * @param customFind Function
 * @param toggleBy - indicating whether to add or remove element
 */
export function toggleInArray<T>(
    element: T,
    array: T[],
    customFind = (el: T) => el === element,
    toggleBy: boolean | undefined = undefined,
): T[] {
    const exists = array.findIndex(customFind) !== -1;

    if (exists === toggleBy) {
        // element doesn't need to be toggled
        return array;
    }

    if (!exists) {
        // add element
        return [...array, element];
    }

    // remove all elements that match
    return array.filter((elem) => !customFind(elem));
}

/**
 * Adds element to the end of an array if the element does not yet exist
 * @param element
 * @param array
 * @param customFind Function
 */
export function addToArrayDistinct<T>(element: T, array: T[], customFind = (el: T) => el === element): T[] {
    if (!array.find(customFind)) {
        array.push(element);
    }

    return array;
}

/**
 * Replaces element in array if it exists.
 * Modifies the original array.
 */
export function replaceInArray<T>(element: T, array: T[], customFind = (el: T) => el === element): T[] {
    const i = array.findIndex(customFind);
    if (i >= 0) {
        // eslint-disable-next-line no-param-reassign
        array[i] = element;
    }

    return array;
}

/**
 * Removes element from array if it exists
 * @param element
 * @param array
 * @param customFind Function
 */
export function removeFromArray<T>(element: T, array: T[], customFind = (el: T) => el === element): T[] {
    const index = array.findIndex(customFind);

    if (index !== -1) {
        array.splice(index, 1);
    }

    return array;
}

/**
 * Removes an element from the array based on the given key-value pair
 * @param value
 * @param array array of objects to search in.
 * @param key key to match
 * @returns updated array with the element removed if found.
 */
export function removeByKeyValue<T, K extends keyof T>(value: T[K], array: T[], key: K = 'id' as K): T[] {
    return removeFromArray(value as unknown as T, array, (el) => el[key] === value);
}

/**
 * Pushes value to arr.
 * If arr is not an Array it will be turned into one.
 */
export function addTo<T>(value: T, arr: T[] = []) {
    return Array.isArray(arr) ? [...arr, value] : [arr, value];
}

/**
 * Returns val inside of an array if not already an array.
 */
export function assureArray<T>(valOrArr: T | T[]): T[] {
    return Array.isArray(valOrArr) ? valOrArr : [valOrArr];
}

/**
 * Initializes a row of numbers as Array.
 * You can adjust the nubmer generation with start & step
 */
export function createArrayOfNumbers(amount: number, start = 1, step = 1): number[] {
    return new Array(amount).fill(null).map((v, i) => i * step + start);
}

/**
 * orders array of objects based on order list
 * @param objects
 * @param order - list of identifies
 * @param attributeName (optional)
 * @returns new ordered array
 */
export function orderObjects<T, K extends keyof T>(objects: T[], order: Array<T[K]>, attributeName: K = 'id' as K) {
    const orderedObjects = order
        .map((value) => objects.find((elem) => elem[attributeName] === value))
        .filter((elem) => elem !== undefined) as T[];
    // add missed objects to end of ordered list
    orderedObjects.push(...difference(objects, orderedObjects));
    return orderedObjects;
}

/**
 * Distributes elements from "array" onto n arrays if arrays of at least minChunkSize can be created.
 */
export function distributeByChunk<T>(array: T[], minChunkSize: number) {
    const numArrays = Math.max(1, Math.floor(array.length / minChunkSize));
    const arraySize = Math.ceil(array.length / numArrays);

    return chunk(array, arraySize);
}

/**
 * Returns a random element from an array
 */
export function takeRandom<T>(array: T[]) {
    return array[Math.floor(Math.random() * array.length)];
}

/**
 * Filters an array of values and only keeps values that are truthy.
 * This function fixes the typing for array filtering.
 */
export function onlyTruthy<T>(arr: T[] = []) {
    return arr.filter(isTruthy) as Array<Exclude<T, Falsy>>;
}
