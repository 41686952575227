<template>
    <BtnMenu
        :options="options"
        :menu-title="$t('share.tooltip')"
        icon="share"
        v-bind="$attrs"
    />
</template>
<script lang="ts">
import { defineComponent, onMounted, onBeforeUnmount, getCurrentInstance } from 'vue';
import MenuItem from '@/components/MenuItem';
import LinkShare from '@/components/LinkShare';
import stripWhiteSpaceSpecialCharacters from '@/helpers/stripWhiteSpaceSpecialCharacters';
import ClipboardJS from 'clipboard';
import BtnMenu from '@/components/BtnMenu';

/**
 * This is a wrapper component that is based on the BtnMenu component but with adjustments to accomodate the share Options
 * The Btn can be modified as a normal button by passing down the attributes that are available in the Btn component.
 */

export default defineComponent({
    name: 'BtnMenuShare',
    components: { BtnMenu },
    inheritAttrs: false,
    props: {
        /**
         * String to define the share Title.
         */
        shareTitle: {
            type: String,
            required: true,
        },
        /**
         * String of the share URL.
         */
        shareUrl: {
            type: String,
            required: true,
        },
    },

    setup() {
        const context = getCurrentInstance();
        let clipboard: ClipboardJS | null = null;
        onMounted(() => {
            clipboard = new ClipboardJS(`#btn-menu--share__copy-link-${context?.proxy?.$id}`);
        });
        onBeforeUnmount(() => {
            if (clipboard) {
                clipboard.destroy();
            }
        });
    },

    computed: {
        options() {
            const linkShareOptions = {
                is: LinkShare,
                tag: MenuItem,
                url: this.shareUrl,
                title: this.shareTitle,
                description: this.$t('post:share.description'),
                hashtags: [stripWhiteSpaceSpecialCharacters(this.shareTitle)].join(','),
            };

            return [
                {
                    icon: 'social-facebook',
                    label: this.$t('share.facebook'),
                    network: 'facebook',
                    ...linkShareOptions,
                },
                {
                    icon: 'social-x',
                    label: this.$t('share.twitter'),
                    network: 'twitter',
                    ...linkShareOptions,
                },
                {
                    icon: 'social-whatsapp',
                    label: this.$t('share.whatsapp'),
                    network: 'whatsapp',
                    ...linkShareOptions,
                },
                {
                    icon: 'mail',
                    label: this.$t('share.email'),
                    network: 'email',
                    ...linkShareOptions,
                },
                {
                    icon: 'anchor-link',
                    label: 'Copy link',
                    'data-clipboard-text': this.shareUrl,
                    id: `btn-menu--share__copy-link-${this.$id}`,
                },
            ];
        },
    },
    methods: {
        stripWhiteSpaceSpecialCharacters,
    },
});
</script>
<style scoped lang="scss"></style>
