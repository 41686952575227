import { BREAKPOINT } from '@/utils/uiUtils';

export const ADSLOTS = Object.freeze({
    mrec: [
        {
            minWidth: 1,
            sizes: [
                [300, 600],
                [300, 250],
                [160, 600],
                [120, 600],
                [240, 400],
                [250, 250],
            ],
        },
    ],
    sponsored_course_header: [
        {
            minWidth: 1,
            sizes: [[427, 23]],
        },
    ],
    fullpage_video: [
        {
            minWidth: 1,
            sizes: [
                [1920, 1080],
                // video format
                [16, 9],
            ],
        },
    ],
    fullpage_video_mew: [
        {
            minWidth: 1,
            sizes: [
                [1080, 1080],
                [1080, 1920],
                // video format
                [16, 9],
            ],
        },
    ],
    banner: [
        {
            minWidth: 1,
            sizes: [
                [1, 1],
                [320, 50],
                [970, 250],
            ],
        },
    ],
    banner_btf: [
        {
            minWidth: 1,
            sizes: [
                [320, 50],
                [970, 250],
            ],
        },
    ],
    bottom_fixed_banner: [
        {
            minWidth: 1,
            sizes: [[320, 50]],
        },
    ],
    banner_btf_2: [
        {
            minWidth: 1,
            sizes: [
                [320, 50],
                [970, 250],
            ],
        },
    ],
    billboard: [
        {
            minWidth: 1,
            sizes: [
                [1, 1],
                [728, 90],
                [970, 250],
                [800, 250],
                [720, 300],
                [300, 250],
                [250, 250],
                [468, 60],
                [300, 100],
                [234, 60],
            ],
        },
    ],
    billboard_btf: [
        {
            minWidth: 1,
            sizes: [
                [1, 1],
                [728, 90],
                [970, 250],
                [800, 250],
                [720, 300],
                [300, 250],
                [250, 250],
                [468, 60],
                [300, 100],
                [234, 60],
            ],
        },
    ],
    superbanner_btf: [
        {
            minWidth: 1,
            sizes: [
                [728, 90],
            ],
        },
    ],
    superbanner_btf_2: [
        {
            minWidth: 1,
            sizes: [[728, 90]],
        },
    ],
});

const counterObj = {
    post: {
        xandrAdCounter: 1,
        counter: 0,
    },
    flashcard: {
        xandrAdCounter: 1,
        counter: 0,
    },
    document: {
        xandrAdCounter: 1,
        counter: 0,
    },
};

// fullscreen cookies values
export const defaultInterval = parseInt(import.meta.env.MIX_XANDR_MAX_NUMBER_OF_DOCS_VIEWED, 10);
export const fullscreenAdInterval = parseInt(import.meta.env.MIX_XANDR_FULLSCREEN_AD_INTERVAL, 10);
const additive = parseFloat(import.meta.env.MIX_XANDR_INTERVAL_ADDITIVE, 10);

/*
 * Logic to calculate the interval of the xandr ads for AB testing
 * */
export function calculateInjectionInterval(baseInterval, sum = 10) {
    const user = sdWindow?.user;

    if (!user?.is_new_onboarded_user) {
        return baseInterval;
    }

    const createdAt = new Date(user?.created_at);
    const today = new Date();
    const diff = today - createdAt;
    const daysSinceCreation = Math.floor(diff / (1000 * 60 * 60 * 24));

    return Math.max(baseInterval, baseInterval + sum - daysSinceCreation * additive);
}

function injectXandrObj(adType, typeItem) {
    return {
        type: adType === 'billboard' ? 'xandrStickyAd' : 'xandrAd',
        adType: `${adType}_btf_${counterObj[typeItem].xandrAdCounter}`,
        sizes: ADSLOTS[adType][0].sizes,
        inFeedAd: true,
    };
}

export function injectXandrAds(data, isLoggedIn, typeItem = 'post') {
    const bannerType = window.innerWidth < BREAKPOINT.MD ? 'banner' : 'billboard';

    return [].concat(
        ...data.map((item) => {
            if (item.type === 'post' && isLoggedIn) {
                counterObj[typeItem].counter += 1;
                const interval = calculateInjectionInterval(7);
                // superbanners, billboards,banners
                if (
                    counterObj[typeItem].counter === 2 ||
                    (counterObj[typeItem].counter > 6 && counterObj[typeItem].counter % Math.round(interval) === 0)
                ) {
                    const xandrObj = injectXandrObj(bannerType, typeItem);

                    counterObj[typeItem].xandrAdCounter += 1;
                    return [item, xandrObj];
                }

                /*
                 * Due to the backend pagination for feedList, we are not able to change the frequency of company_widget,
                 * we decided to move that logic to frontend to have more control and being able to change the show frequency
                 * */
                if (
                    counterObj[typeItem].counter === 6 ||
                    (counterObj[typeItem].counter > 6 && (counterObj[typeItem].counter + 1 - 6) % 21 === 0)
                ) {
                    const feedItem = {
                        type: 'company_widget',
                    };
                    return [item, feedItem];
                }
            } else if (typeItem === 'document' && isLoggedIn) {
                counterObj[typeItem].counter += 1;
                const interval = calculateInjectionInterval(10);

                if (parseInt(counterObj[typeItem].counter.toString().slice(-1), Math.round(interval)) === 2) {
                    const xandrObj = injectXandrObj(bannerType, typeItem);

                    counterObj[typeItem].xandrAdCounter += 1;
                    return [item, xandrObj];
                }
            } else if (typeItem === 'flashcard' && isLoggedIn) {
                counterObj[typeItem].counter += 1;
                const interval = calculateInjectionInterval(10);

                if (parseInt(counterObj[typeItem].counter.toString().slice(-1), Math.round(interval)) === 2) {
                    const xandrObj = injectXandrObj(bannerType, typeItem);

                    counterObj[typeItem].xandrAdCounter += 1;
                    return [item, xandrObj];
                }
            }

            return item;
        }),
    );
}
