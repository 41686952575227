<template>
    <label
        data-testid="input-checkbox"
        :class="[
            'flex items-center',
            {
                'cursor-pointer': !disabled,
                'chips-design-item-checked': hasChipsDesign && isChecked,
                chip: hasChipsDesign && !isChecked,
            },
            $attrs.class,
        ]"
    >
        <input
            ref="inputCheckbox"
            v-bind="$attrs"
            :disabled="disabled"
            :checked="isChecked"
            type="checkbox"
            class="hidden"
            @change="onChange($event.target.checked)"
        />
        <span
            :class="[
                {
                    'checkbox w-5 m-0.5 border-2 border-slate-200 rounded shadow-vertical-grey bg-white flex-shrink-0':
                        !hasChipsDesign,
                    icon: hasChipsDesign && !isChecked,
                    'visible-icon': hasChipsDesign && isChecked,
                },
                'h-5 flex items-center justify-center',
            ]"
        >
            <svg
                width="14"
                height="10"
                viewBox="0 0 14 10"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                :class="{ hidden: !hasChipsDesign || !isChecked }"
            >
                <path
                    :style="inputCheckboxSvgPathStyle"
                    :d="
                        isChecked && hasChipsDesign
                            ? 'M12.5 1.40259L4.92749 8.59741L1.5 4.59723'
                            : 'M12 1.66669L5.125 8.33335L2 5.30305'
                    "
                />
            </svg>
        </span>
        <span
            v-if="$slots.default"
            :class="[
                {
                    'ml-2': shouldHaveMargin,
                    'text-slate-400': disabled,
                },
            ]"
        >
            <!-- @slot custom html/text content displayed after the checkbox -->
            <slot></slot>
        </span>
    </label>
</template>

<script>
import { toggleInArray } from '@/helpers/array';

/**
 * Basic checkbox component which inherits all attributes of default html checkboxes.
 *
 * It is used to handle boolean inputs on listed items where **multiple** selections are allowed.
 * But it can also be used to indicate the option to opt-in whn used alone.
 */
export default {
    name: 'InputCheckbox',
    inheritAttrs: false,
    props: {
        /**
         * State of the checkbox
         */
        modelValue: {
            type: [Boolean, Array],
            default: false,
        },
        /**
         * When `v-model` or the `modelValue` prop is of type array, `value` will be toggled in the array
         */
        value: {
            type: undefined,
            default: undefined,
        },
        /**
         * Disable the checkbox
         */
        disabled: {
            type: Boolean,
            default: false,
        },
        /**
         * Adding chip design & style to the radio input
         */
        hasChipsDesign: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue', 'change', 'input'],

    computed: {
        isChecked() {
            return this.isArrayValue ? this.modelValue.includes(this.value) : this.modelValue;
        },
        isArrayValue() {
            return Array.isArray(this.modelValue);
        },
        shouldHaveMargin() {
            return !this.hasChipsDesign || this.isChecked;
        },
        inputCheckboxSvgPathStyle() {
            return [
                this.isChecked && this.hasChipsDesign
                    ? { stroke: 'white', 'stroke-width': 1.4 }
                    : { stroke: this.disabled ? 'rgb(var(--colors-slate-400))' : 'black', 'stroke-width': 2 },
                { 'stroke-linecap': 'round', 'stroke-linejoin': 'round' },
            ];
        },
    },

    methods: {
        onChange(checked) {
            const val = this.isArrayValue ? toggleInArray(this.value, this.modelValue, undefined, checked) : checked;
            this.$emit('update:modelValue', val);
            this.$emit('change', val);
            this.$emit('input', val);
        },
        triggerClick() {
            this.$refs.inputCheckbox.click();
        },
    },
};
</script>

<style lang="scss" scoped>
@screen lg {
    .chip:hover {
        @apply bg-blue-200;
    }
}
input[type='checkbox'] {
    &:checked + .checkbox svg {
        display: block;
    }
}

.chips-design-item-checked {
    @apply bg-blue-600 text-white;
}
.icon {
    max-width: 0;
    transition: max-width 150ms ease-out;
    opacity: 0;
}

.visible-icon {
    max-width: 20px;
    transition: max-width 150ms ease-out;
    opacity: 1;
}
</style>
