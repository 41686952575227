import { useId } from '@/composables/id';
import type { Plugin } from 'vue';

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        /**
         * Component specific id provided by our IdPlugin
         */
        $id: string;
    }
}

export default {
    /**
     * Adds a unique id to each component
     */
    install(app) {
        app.mixin({
            beforeCreate() {
                if (this.$id) return;
                this.$id = useId();
            },
        });
    },
} as Plugin;
