/* eslint-disable no-underscore-dangle */

async function isUserCentricsLoaded(): Promise<boolean> {
    if (window.__ucCmp) return window.__ucCmp.isInitialized();

    return new Promise((resolve) => {
        let i = 0;
        const wait = setInterval(() => {
            if (i === 10) {
                resolve(false);
            }

            i += 1;
            if (window.__ucCmp) {
                clearInterval(wait);
                resolve(window.__ucCmp.isInitialized());
            }
        }, 500);
    });
}

export async function isServiceAllowed(serviceName: string): Promise<boolean> {
    const isLoaded = await isUserCentricsLoaded();
    if (!isLoaded) return false;

    const details = await window.__ucCmp.getConsentDetails();
    const services = Object.values(details.services);

    return services.find((service) => service.name === serviceName)?.consent?.given ?? false;
}
